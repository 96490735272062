<template>
    <div id="app">
        <div v-if="isAuthenticated && !isFullPage">
            <Header></Header>
            <ErrorBoundary>
                <div class="router container-fluid scrollable-content">
                    <router-view />
                </div>
            </ErrorBoundary>
            <Footer AppName="Dealer Portal" style="margin-top: 30px;"></Footer>
        </div>

        <div v-else>
            <router-view />
        </div>
    </div>
</template>
<style lang="scss">
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    .navbar {
        padding-bottom: 0 !important;
    }

    .router {
        padding: 5px 4% 5% 4% !important;
        height: 100%;
    }

    .base {
        min-height: 100px;
    }

    //.ThemedBackground {
    //    background-color: $TemplateColor !important;
    //}

    .ThemedBackground {
        background-image: linear-gradient(to bottom, $TemplateGradient1, $TemplateGradient2);
    }
  

    .ThemedBorder {
        border-color: $TemplateColor !important;
    }

    .noBoxSizing {
        box-sizing: border-box !important;
    }
</style>

<script lang="ts">
    import Header from "@/components/Header.vue";
    import Footer from "@/components/Footer.vue";
    import { Options, Vue } from 'vue-class-component'
    import { IdentificationModule } from "@/store/modules/identification/identification";
    import axios from "axios";
    import { AzureIdentificationModule, grabToken } from "@/store/modules/identification/azureIdentification";
    import { Country, UserAccountInfo, UserAccountModule } from "@/store/modules/useraccount";
    import { CurrentUser } from "@/model/currentuser/CurrentUser";
import notify from "devextreme/ui/notify";
import { AxiosModule } from "./store/modules/identification/axiosModule";
    import { auth } from '@/store/modules/identification/MsalAuth';
import { Auth } from "./services/MsalService";
import ErrorBoundary from "./components/ErrorBoundary.vue";

    @Options({
        components: { Header, Footer, ErrorBoundary },
    })
    export default class App extends Vue {
        tokenInterval = 0
        private tokenTimeout: NodeJS.Timeout | number = 0

        get useMSAL(): boolean {
            return process.env.VUE_APP_USE_MSAL === 'true'
        }

        get isLocalAccount(): boolean {
            return AzureIdentificationModule.isLocalAccount
        }

        get identified(): boolean {
            return IdentificationModule.tokenData !== null;
        }

        get isAuthenticated(): boolean {
            if (this.useMSAL) {
                return AzureIdentificationModule.authenticated //&& AzureIdentificationModule.hasTokenData
            } else {
                return this.identified
            }
        }

        get isFullPage(): boolean {
            return this.$router.currentRoute.value.fullPath.toLowerCase() === '/forbidden' // || this.$router.currentRoute.value.fullPath.toLowerCase() === '/changepassword'
        }

        get newUser(): boolean {
            return !AzureIdentificationModule.isInRole('Portal-Customer')
        }

        created(): void {
            if (this.useMSAL && auth.initialized && this.isAuthenticated) {
                addEventListener('RefreshTokenTimer', () => this.resetTokenTimer(), false)
                this.getToken()
                AxiosModule.setRequestInterceptor()
                AxiosModule.setResponseInterceptor()

                this.getAllowedAddressInfo()
                this.grabUserInfo()
            }

            this.$watch(() => this.isAuthenticated, async () => {
                if (this.useMSAL && auth.initialized && this.isAuthenticated) {
                    addEventListener('RefreshTokenTimer', () => this.resetTokenTimer(), false)
                    await this.getToken()
                    AxiosModule.setRequestInterceptor()
                    AxiosModule.setResponseInterceptor()

                    this.getAllowedAddressInfo()
                    this.grabUserInfo()
                    if (!this.newUser) {
                        this.getLeadTimePreloaded()
                    }
                }
            });
        }

        getAllowedAddressInfo(): void {
            axios.get('api/ValidAddressInfo/GetValidAddressInfo').then(response => {
                UserAccountModule.setCountries(response.data as Country[])
            })
        }

        grabUserInfo(): void {
            if (!this.newUser) {
                axios.get('api/CurrentUser/GrabCurrentUser').then(response => {
                    AzureIdentificationModule.setCurrentUser(response.data as CurrentUser)
                    axios.get('odata/AccountInformation?addIfNew=false').then(response => {
                        UserAccountModule.setUserInfo(response.data.value[0] as UserAccountInfo)
                    }).catch(error => {
                        console.log('Do Nothing')
                    })
                }).catch(error => {
                    console.log('Do Nothing')
                })
            } else {
                this.$router.push('/Account/Information')
            }
        }

        beforeUnmount(): void {
            clearTimeout(this.tokenTimeout)
        }

        async getToken(): Promise<string> {
            const token = await grabToken()
            this.resetTokenTimer()
            return token
        }

        /**
         * Called whenever token is grabbed (grabToken or grabTokenOverride) outside of the timer
         */
        resetTokenTimer(): void {
            let exp = 1000 * 60 * 55
            if (AzureIdentificationModule.expirationTime) {
                let expDate = new Date(AzureIdentificationModule.expirationTime)
                expDate.setUTCMinutes(expDate.getUTCMinutes() - 5)

                exp = expDate.getTime() - Date.now()
                if (exp < 0) {
                    exp = 1000 * 60
                }
            }
            console.log('getToken next check-in: ' + ((exp / 1000) / 60)) // TODO: remove after token issues resolved
            clearTimeout(this.tokenTimeout)
            this.tokenTimeout = setTimeout(this.getToken, exp)
        }

        getLeadTimePreloaded(): void {
            axios.get(`api/LeadTime/GetLeadTimeOnce`).then(response => {
                //console.log(response)
            }).catch(error => {
                console.log(error)
            })
        }
    }
</script>
